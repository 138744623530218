<!-- 我的申报 详情 -->
<template>
  <div>
    <!--        <headtitle Otitle="我的申报"/>-->
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>我的申报</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-20"></div>
      <div class="tittool"></div>

      <el-row class="row-center">
        <h2
          v-html="Title"
          style="text-align: left;font-weight: 400;color: #1192ee;"
        ></h2>
        <div class="hr-10"></div>
        <el-col :span="22">
          <el-card shadow="never">
            <div style="font-size: 18px;font-weight: bold">填报信息</div>
            <div style="font-size: small; margin-top: 8px">
              <span
                :title="item.label + item.value"
                v-for="(item, k) in tableData"
                :key="k"
                :style="'display:' + item.type"
              >
                <div class="text-size">{{ item.label }}：{{ item.value }}</div>
              </span>
            </div>
          </el-card>
        </el-col>
        <el-col :span="2">
          <!--                    <div class="fl" style="margin-left: 16px">照片:</div>-->
          <!--                    <img src="@/assets/user.jpg" width="75%" height="75%" alt/>-->
          <div v-for="(item, i) in filelis" :key="i">
            <img
              v-if="iSBoolean(item.name, '头像')"
              :src="fileurl + item.path"
              width="75%"
              height="75%"
              alt
            />
          </div>
        </el-col>
      </el-row>

      <el-row class="row-center">
        <el-col :span="22">
          <el-card shadow="never" style="margin-top: 8px">
            <div>附件材料查看下载</div>
            <div>
              <el-button
                class="ccyc"
                :title="item.name"
                type="primary"
                size="small"
                plain
                v-for="(item, i) in filelis"
                :key="i"
                @click="downloaded(item.path)"
                >{{ item.name }}
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <div v-show="status === 20" style="width: 85%;margin-left: 40px">
        <div>驳回理由</div>
        <el-input type="textarea" readonly v-model="memo"></el-input>
      </div>

      <div v-show="status === 10" style="width: 85%;margin-left: 40px">
        <div>驳回理由</div>
        <el-input type="textarea" readonly v-model="memo"></el-input>
      </div>

      <el-button plain @click="back" style="margin: 8px 0">返回</el-button>
    </el-card>
  </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
import configDate from "@/utils/config";

export default {
  components: {
    //headtitle
  },
  data() {
    return {
      fileurl: configDate.fileurl,
      status: 0,
      memo: "",
      Ids: [],
      tableData: [],
      Title: "",
      filelis: [],
      multipleSelection: [],
      gridData: [],
      tabletSatuses: {
        affairId: "",
        userId: "",
      },
    };
  },
  created() {
    this.getDate();
    this.Ids = this.$route.params.id;
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    handleSelectionChange(val) {},
    getDate() {
      const self = this;
      const policyId = this.$route.params.id.split(":")[0];
      const id = this.$route.params.id.split(":")[1];

      //表单详情
      this.$axios
        .get("/policy?id=" + policyId)
        .then(function(res) {
          self.Title = res.data.data.title || "";
          let newarr = [];
          res.data.data.form.inputs.forEach(function(item) {
            //console.log(item.type=="file");

            let ThisType = item.type == "file" ? "none" : "blick";

            //let ThisType = item.name.indexOf("Upload") != -1 ? "none" : "blick";
            // if (
            //   item.name == "major" ||
            //   item.name == "dishonesty" ||
            //   item.name == "socialSecurity" ||
            //   item.name == "serviceDeclaration" ||
            //   item.name == "recordRegistration" ||
            //   item.name == "declarationAttachment"
            // ) {
            //   ThisType = "none";
            // }

            newarr.push({
              type: ThisType,
              label: item.label,
              value: "",
              [item.name]: "",
            });
          });

          //事务详情
          self.$axios
            .get("/affair?id=" + id)
            .then(function(res) {
              self.tabletSatuses.userId = res.data.data.ownerId;
              self.tabletSatuses.affairId = res.data.data.id;
              let separate = [];
              res.data.data.values.forEach(function(item) {
                if (item.name.indexOf("Upload") != -1) {
                  self.getFlelis(item.value);
                } else if (
                  item.name == "dishonesty" ||
                  item.name == "socialSecurity" ||
                  item.name == "serviceDeclaration" ||
                  item.name == "recordRegistration" ||
                  item.name == "declarationAttachment"
                ) {
                  self.getFlelis(item.value);
                } else {
                  separate.push(item);
                }
              });

              separate.forEach(function(item, i) {
                //console.log(item);
                newarr.forEach(function(list, index) {
                  if (item.label === list.label) {
                    newarr[index].value = item.value;
                  }
                });
              });

              self.$axios
                .get(
                  "/affair/statuses?affairId=" +
                    self.tabletSatuses.affairId +
                    "&userId=" +
                    self.tabletSatuses.userId
                )
                .then((response) => {
                  self.affairStatusId =
                    response.data.data[response.data.data.length - 1].id;
                  self.$axios
                    .get("/affair/status?affairStatusId=" + self.affairStatusId)
                    .then((response) => {
                      //console.log(response)
                      self.memo = response.data.data.content;
                      self.status = response.data.data.status;
                    });
                });
            })
            .catch(function(error) {
              console.error(error);
            });

          //console.log(newarr);
          self.tableData = newarr;
          //console.log('表单:',self.tableData)
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    back() {
      this.$router.back();
    },
    //下载
    downloaded(path) {
      //console.log(path);
      window.location.href = this.fileurl + path;
    },
    getFlelis(ID) {
      var self = this;
      console.log(ID);
      this.$axios.get("/file/record?id=" + ID).then(function(res) {
        // console.log(res)
        self.filelis.push({
          name: res.data.data.memo,
          path: res.data.data.path,
        });
        console.log(self.filelis);
      });
    },
    handleEdit(index, row) {},
  },
  computed: {
    // 控制显示的内容
    iSBoolean() {
      return function(value1, value2) {
        //console.log(value1, value2);
        return value1 == value2;
      };
    },
  },
};
</script>

<style lang="less" scoped>
.ccyc {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.headtit {
  background: #1090e7;
  height: 50px;
  text-align: left;
  line-height: 50px;
  padding: 0 0 0 16px;
  color: #fff;
  font-size: 16px;
}

.buttbut {
  overflow: hidden;
  width: 500px;
  margin: 0 auto;
}

.basicCoxs {
  h2 {
    font-size: 16px;
    margin: 0;
    height: 35px;
    text-align: left;
  }

  .cailxx {
    span {
      margin: 10px 0;
    }
  }

  .fujianx {
    button {
      width: 45%;
      float: left;
      background: #fff;
      margin: 5px;
      text-align: left;
      cursor: pointer;
    }
  }

  span {
    background: #f3f3f3;
    display: block;
    height: 30px;
    text-align: left;
    padding: 0 0 0 16px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 185px;
    margin: 9px;
  }
}

.row-center {
  margin: 16px 40px;

  span {
    font-size: 15px;
    font-weight: bold;
    display: block;
    height: 35px;
    text-align: left;
    padding: 0 0 0 10px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 45%;
    margin: 9px;
  }

  .text-size {
    font-weight: lighter;
    display: inline-block;
  }

  button {
    width: 280px;
    margin: 16px 24px;
  }
}

.small {
  width: 240px;
  border-radius: 2px;
}
</style>
